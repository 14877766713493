<template>
  <div id="invitationInfo">
    <b-card class="mb-3">
      <template #header>
        <b-icon-info-circle/> {{ $t('comp.invitationinfo.details.label') }}
      </template>
      <ul>
        <li><strong>{{ $t('comp.invitationinfo.createdAt.label') }}:</strong> {{ formatDate(invitation.createdAt) }}</li>
        <li><strong>{{ $t('comp.invitationinfo.forProject.label') }}:</strong> {{ invitation.forProject.label }}</li>
        <li><strong>{{ $t('comp.invitationinfo.forValidity.label') }}:</strong> {{ formatDate(invitation.forValidity) }}</li>
        <li v-if="invitation.supervisor"><strong>{{ $t('comp.invitationinfo.supervisor.label') }}:</strong> {{ invitation.supervisor }}</li>
        <li><strong>{{ $t('comp.invitationinfo.message.label') }}:</strong> {{ invitation.message }}</li>
        <li><strong>{{ $t('comp.invitationinfo.state.label') }}:</strong> {{ $t(`states.${invitation.state}.label`) }}</li>
        <li v-if="invitation.withdrawalReason"><strong>{{ $t('comp.invitationinfo.withdrawalreason.label') }}:</strong> {{ invitation.withdrawalReason }}</li>
        <li v-if="invitation.account"><strong>{{ $t('comp.invitationinfo.account.label') }}:</strong> {{ invitation.account }}</li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'InvitationInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
  },
  props: {
    invitation: {
      type: Object,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    formatDate (date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : '-'
    }
  }
}
</script>

<style scoped>
</style>
