<template>
  <div class="accounts" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('accounts.title') }}</h1>
    <b-form v-if="showForm" @submit="onSubmit" @reset="onReset">
      <FormButtons @goBack="goBack" :has-delete-button="!!formReset.username" @delete="deleteAccount(form)"/>
      <b-card class="mx-2 mx-md-5" :header="formCardHeader">
        <b-form-group :label="$t('comp.accounts.username.label')"
                      :description="$t('comp.accounts.username.description')" >
          <b-form-input v-model="form.username" required disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.homeDirectory.label')"
                      :description="$t('comp.accounts.homeDirectory.description')">
          <b-form-input v-model="form.homeDirectory" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.uid.label')" :description="$t('comp.accounts.uid.description')">
          <b-form-input v-model="form.uid" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.state.label')" label-for="state"
                      :description="$t('comp.accounts.state.description')">
          <StateSelect :selected-state.sync="form.state" entity="account" view="admin"/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.project.label')" label-for="project"
                      :description="$t('comp.accounts.project.description')">
          <div class="d-flex">
            <!-- Synchronize calculated validity based on project only on create-->
            <ProjectSelect v-if="formEdit" class="w-75" view="admin"  :is-disabled="disableProjectSelect" :selected-project.sync="form.project" :loadValidity="false"/>
            <ProjectSelect v-else          class="w-100" view="admin" :is-disabled="disableProjectSelect" :selected-project.sync="form.project" :selected-validity.sync="form.validUntil"  />
            <b-button v-if="formEdit" class="w-25" @click="forwardProject" variant="primary">
              <b-icon-forward-fill/> {{ $t('comp.accounts.gotoproject', {project: form.project.key}) }}
            </b-button>
          </div>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.otherprojects.label')" label-for="otherprojects"
                      :description="$t('comp.accounts.otherprojects.description')">
          <!-- Switches to SingleSelect if Array empty: Force Multiselect-->
          <ProjectSelect :selected-projects.sync="form.otherProjects" view="admin" :is-disabled="disableProjectSelect" :loadValidity="false" multiple/>
        </b-form-group>
          <b-form-group :label="$t('comp.accounts.user.label')" label-for="user"
                        :description="$t('comp.accounts.user.description')">
            <b-input-group class="d-md-flex flex-nowrap">
              <b-input-group-prepend is-text v-b-hover="handleLockHover" :title="$t('comp.accounts.unlockuser.title')" role="button" @click="unlockUserSelect(lockUserSelect)">
                <b-icon v-if="lockUserSelect  && isLockHovered" icon="lock-fill"></b-icon>
                <b-icon v-if="!lockUserSelect && isLockHovered" icon="unlock-fill"></b-icon>
                <b-icon v-if="lockUserSelect  && !isLockHovered" icon="lock"   ></b-icon>
                <b-icon v-if="!lockUserSelect && !isLockHovered" icon="unlock" ></b-icon>
              </b-input-group-prepend>
              <UserSelect :selected-user.sync="form.user" view="admin" :is-disabled="lockUserSelect" style="width:inherit;"/>
            </b-input-group>
          </b-form-group>
        <b-form-group :label="$t('comp.accounts.prefix.label')"
                      :description="$t('comp.accounts.prefix.description')">
          <b-form-input type="text" v-model="form.prefix" :disabled="formEdit" :maxlength="prefixLength"/>
        </b-form-group>
        <b-form-group :label="$t('comp.accounts.validUntil.label')" label-for="valid-until"
                      :description="$t('comp.accounts.validUntil.description')">
          <!-- Sync Function only in Edit: Create-Form already syncs selected projects validity date -->
          <b-input-group v-if="formEdit" class="d-md-flex flex-nowrap">
            <b-input-group-prepend class="pb-2" is-text v-b-hover="handleDateSyncHover"
                                  :title="isDateSyncOk ? $t('comp.accounts.syncdateok.title') : $t('comp.accounts.syncdate.title')"
                                  role="button" @click="syncDate()">
              <b-iconstack v-if="isDateSyncHovered && !isDateSyncOk">
                <b-icon stacked icon="circle-fill"></b-icon>
                <b-icon stacked icon="arrow-repeat" rotate="135" scale="0.75" variant="light"></b-icon>
              </b-iconstack>
              <b-iconstack v-if="!isDateSyncHovered && !isDateSyncOk">
                <b-icon stacked icon="circle"></b-icon>
                <b-icon stacked icon="arrow-repeat" rotate="135" scale="0.75"></b-icon>
              </b-iconstack>
              <b-icon v-if="isDateSyncHovered && isDateSyncOk" icon="check-circle-fill" ></b-icon>
              <b-icon v-if="!isDateSyncHovered && isDateSyncOk" icon="check-circle" ></b-icon>

            </b-input-group-prepend>
            <b-form-datepicker no-flip id="valid-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validUntil"
                              v-bind="datePickerLabels || {}" class="mb-2" :value-as-date="true" @shown="isDateSyncOk = false"/>
          </b-input-group>
          <b-form-datepicker v-else no-flip id="valid-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validUntil"
                             v-bind="datePickerLabels || {}" class="mb-2" :value-as-date="true"/>
        </b-form-group>
      </b-card>
      <b-card v-if="formEdit" :header="$t('comp.accounts.publicKeys.title')" class="mx-2 mx-md-5 my-3">
        <PublicKeys :account="form" view="admin"/>
      </b-card>
      <CommentCard :targetId="this.form.username.toString()" :new-comment.sync="newComment" :showTable="formEdit" view="admin"/>
    </b-form>
    <div class="overflow-auto px-2 px-md-5" v-else>
      <div class="row justify-content-between justify-content-md-start mt-lg-3">
        <div class="col-12 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="addAccount" variant="success">
            <b-icon-plus/> {{ $t('comp.accounts.add.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('nhraccounts')" variant="primary">
            <b-icon-funnel-fill v-if="nhrAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showNhrOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('fauaccounts')" variant="primary">
            <b-icon-funnel-fill v-if="fauAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showFauOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('active')" variant="primary">
            <b-icon-funnel-fill v-if="activeAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showActive.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-3 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('inactive')" variant="primary">
            <b-icon-funnel-fill v-if="inactiveAccountsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.accounts.showInactive.label') }}
          </b-button>
        </div>
        <div class="col-12 col-md-auto mb-3 ml-md-auto align-self-center">
          <b-form-checkbox v-model="showId" name="showId" switch>
          {{ $t('comp.accounts.showId.label') }}
          </b-form-checkbox>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="accounts-table"/>
      <b-table responsive id="accounts-table" ref="accounts-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" small :items="accountItemProvider" striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editAccount(data.item)" :title="$t('comp.accounts.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteAccount(data.item)" :title="$t('comp.accounts.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
          <b-button v-b-modal="`usage-modal-${data.item.username}`" :title="$t('comp.accounts.showusage.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-bar-chart-line class="mr-1" variant="info"/>
          </b-button>
          <b-modal :id="`usage-modal-${data.item.username}`" :title="$t('comp.accounts.usage.display.label', { account: data.item.username })" size="xl">
            <div>
              <AccountUsageInfo :account="data.item.username"  view="admin"/> <!-- type="account" -->
            </div>
          </b-modal>
        </template>
        <template v-slot:cell(mail)="data">
          <a v-if="data.item.mail" :href="`mailto:${data.item.mail}?cc=hpc-support@fau.de&subject=HPC-Portal`">{{ data.item.mail }}</a>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { accountFilterMixin } from '@/mixins/tableFilter.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import { accountServiceForAdminView } from '@/services/account.service'
import { projectServiceForAdminView } from '@/services/project.service'
import { commentServiceForAdminView } from '@/services/comment.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import CommentCard from '@/components/generic/helper/CommentCard'
import PublicKeys from '@/components/generic/PublicKeys'
import StateSelect from '@/components/generic/select/StateSelect'
import UserSelect from '@/components/generic/select/UserSelect'
import ProjectSelect from '@/components/generic/select/ProjectSelect'
import AccountUsageInfo from '@/components/generic/info/AccountUsageInfo'

export default {
  name: 'Accounts',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin, accountFilterMixin],
  components: {
    PublicKeys,
    TableHeader,
    FormButtons,
    CommentCard,
    StateSelect,
    UserSelect,
    ProjectSelect,
    AccountUsageInfo
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      form: null,
      formEdit: false,
      disableProjectSelect: false, // Enable projectSelect for both account types permanently
      // existingPrefix: '', // Add ':prefix="existingPrefix"' to <ProjectSelect/> to reactivate additional prefix filter
      formReset: null,
      showForm: false,
      showDateTimeDecadeNav: true,
      prefixLength: 4,
      newComment: '',
      changes: [],
      lockUserSelect: true,
      isLockHovered: false,
      isDateSyncHovered: false,
      isDateSyncOk: false,
      showId: false,
      emptyForm: {
        username: '',
        homeDirectory: '',
        uid: '',
        project: null,
        otherProjects: [],
        user: null,
        publicKeys: [],
        state: 'active',
        prefix: '',
        createdAt: null,
        validUntil: null
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false },
        { key: 'username' },
        { key: 'homeDirectory' },
        { key: 'uid' },
        { key: 'project', select: true },
        // { key: 'otherProjects', select: true, array: true },
        { key: 'prefix' },
        { key: 'user', select: true },
        { key: 'mail', sortable: false },
        { key: 'state', state: true },
        { key: 'createdAt', date: true, searchable: false },
        { key: 'validUntil', date: true, searchable: false }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.accounts.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            if (this.showId === false) {
              return _.has(value, 'label') ? value.label : ''
            } else {
              return _.has(value, 'label') ? `${value.label} (${value.key})` : ''
            }
          }
        }
        if (field.select && field.array) {
          field.formatter = (value) => {
            if (value !== null) {
              let arrayLabel = ''
              value.forEach((elem, index) => {
                arrayLabel += _.has(elem, 'label') ? elem.label : ''
                if (index !== value.length - 1) {
                  arrayLabel += ', '
                }
              })
              return arrayLabel
            }
          }
        }
        if (field.state) {
          field.formatter = (value) => {
            return this.$i18n.t(`states.${value}.label`)
          }
        }
      })
      return fields
    },
    formCardHeader () {
      if (this.formEdit === true) {
        return this.$i18n.t('comp.accounts.edit.label')
      } else {
        return this.$i18n.t('comp.accounts.add.label')
      }
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.accounts.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    accountServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    accountServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  beforeMount () {
  },
  methods: {
    refresh () {
      accountServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['accounts-table']) {
        this.$refs['accounts-table'].refresh()
      }
    },
    addAccount () {
      this.formEdit = false
      // this.disableProjectSelect = false // Now always enabled
      // this.existingPrefix = ''          // Now without prefilter
      this.formReset = _.cloneDeep(this.emptyForm)
      this.form = _.cloneDeep(this.formReset)
      this.showForm = true
      this.lockUserSelect = false
      this.isDateSyncOk = false
    },
    editAccount (item) {
      this.formEdit = true
      item.validUntil = item?.validUntil ? new Date(item.validUntil) : null // Format to JSDate for display
      // this.disableProjectSelect = true  // Now always enabled
      this.formReset = _.cloneDeep(item)
      this.form = _.cloneDeep(this.formReset)
      // if (this.form.prefix !== '') { // Disabled differentiation between account types
      //   this.disableProjectSelect = false         // Now always enabled
      //   // this.existingPrefix = this.form.prefix // Now without prefilter
      // }
      this.showForm = true
      this.form.user.label = item?.mail ? `${item.user.label} (${item.mail})` : item.user.label // if mail: reassemble label, else: use as provided
      if (this.form.user != null) {
        this.lockUserSelect = true
      } else {
        this.lockUserSelect = false
        this.isDateSyncOk = false
      }
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.formEdit) {
        // Update Account
        accountServiceForAdminView.update(this.form).then(
          response => {
            // Log changes if any or save comment
            this.logChanges(this.formEdit, this.form.username)
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.form.username, code: response.code }),
              this.$i18n.t('comp.accounts.updated.title'),
              'success'
            )
            // Mail Error return on reactivation
            if (response?.mailStatus === 500) {
              this.makeToast(
                this.$i18n.t('error.text', { status: response.mailStatus, message: 'Internal Server Error: Invitation created, but mail could not be sent!', id: response.user.label }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            }
            this.goBack()
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.username }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      } else {
        accountServiceForAdminView.create(this.form).then(
          response => {
            // Save initial comment
            this.logChanges(this.formEdit, response.username)
            this.makeToast(
              this.$i18n.t('created.text', { id: response.username, code: response.code }),
              this.$i18n.t('comp.accounts.created.title'),
              'success'
            )
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => {
            if (error.status === 409) {
              this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.tier3exists.error'), id: this.form.prefix }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            } else {
              this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            }
          }
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.form = _.cloneDeep(this.formReset)
      this.form.user.label = this?.formReset?.mail ? `${this.formReset.user.label} (${this.formReset.mail})` : this.formReset.user.label // if mail: reassemble label, else: use as provided
      this.newComment = ''
      this.changes = []
      this.lockUserSelect = true
      this.isDateSyncOk = false
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    deleteAccount (account) {
      this.$bvModal.msgBoxConfirm(
        this.$i18n.t('comp.accounts.delete.description', { username: account.username }),
        {
          title: this.$i18n.t('comp.accounts.delete.label') + '?',
          okVariant: 'danger',
          okTitle: this.$i18n.t('confirm.delete.label'),
          cancelTitle: this.$i18n.t('no.label')
        })
        .then(value => {
          if (value === true) {
            accountServiceForAdminView.delete(account.username).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: account.username, code: response.code }),
                  this.$i18n.t('comp.accounts.deleted.title'),
                  'success')
                if (this.formEdit) { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: account.username }),
                this.$i18n.t('result.error.title'),
                'danger')
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.showForm = false
      this.form = null
      this.formReset = null
      this.formEdit = false
      this.newComment = ''
      this.changes = []
      this.lockUserSelect = true
      this.isDateSyncOk = false
      // this.disableProjectSelect = false  // Now always enabled
      // this.existingPrefix = ''           // Now without prefilter
    },
    accountItemProvider (ctx) {
      return accountServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    logChanges (forEdit, targetId) {
      // Collect Changes only on Edit
      if (forEdit) {
        Object.keys(this.form).forEach(key => {
          if (key === 'user') { // prevent logging due to reassembly
            if (!_.isEqual(this.form.user.key, this.formReset.user.key)) {
              const userChange = { field: key, before: `${this.formReset.user.label} (${this.formReset.mail})`, after: this.form[key] }
              this.changes.push(userChange)
            }
          } else { // all other
            if (!_.isEqual(this.form[key], this.formReset[key])) {
              const newChange = { field: key, before: this.formReset[key], after: this.form[key] }
              this.changes.push(newChange)
            }
          }
        })
      }
      // Log Changes if any changes (Edit only) or if comment exists (create/edit)
      if (this.changes.length > 0 || this.newComment !== '') {
        commentServiceForAdminView.create({ targetId: targetId.toString(), comment: this.newComment, changes: this.changes }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: 'Log: ' + response.comment, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.title }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      }
    },
    handleLockHover (hovered) {
      this.isLockHovered = hovered
    },
    handleDateSyncHover (hovered) {
      this.isDateSyncHovered = hovered
    },
    unlockUserSelect (locked) {
      if (locked) {
        this.$bvModal.msgBoxConfirm(
          this.$i18n.t('comp.accounts.unlockuser.text'),
          {
            title: this.$i18n.t('comp.accounts.unlockuser.label') + '?',
            okVariant: 'warning',
            okTitle: this.$i18n.t('confirm.unlock.label'),
            cancelTitle: this.$i18n.t('no.label')
          })
          .then(value => {
            if (value === true) {
              this.lockUserSelect = false
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.lockUserSelect = true
      }
    },
    syncDate () {
      if (this?.form?.project) {
        return projectServiceForAdminView.getValidity(this.form.project.key).then((response) => {
          if (response?.validUntil) {
            this.form.validUntil = new Date(response.validUntil) // Format to JSDate for display
            this.isDateSyncOk = true
            this.makeToast(
              this.$i18n.t('syncsuccess.text', { id: this.form.project.key }),
              this.$i18n.t('result.success.title'),
              'success'
            )
          } else {
            this.makeToast(
              this.$i18n.t('syncinfo.text', { id: this.form.project.key }),
              this.$i18n.t('result.info.title'),
              'info'
            )
          }
        }).catch(error => {
          console.log(error)
          this.makeToast(
            this.$i18n.t('syncerror.text', { status: error.status, message: error.message, id: this.form.project.key }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        })
      } else {
        console.log('No project to sync date with!')
      }
    },
    forwardProject () {
      this.$emit('gotoProject', this.form.project.key)
      this.goBack()
    }
  }
}
</script>
