<template>
  <div>
    <div v-if="language === 'de'">

      <!--<h2>Einhaltung der europäischen und nationalen Exportkontroll-Verordnungen (Stand Mai 2023)</h2>-->

      <p>
        Es ist mir bewusst, dass wissenschaftliche Ergebnisse, die durch die Nutzung von Hochleistungsrechnern erzielt werden, den nationalen, europäischen und internationalen
        Exportkontroll-Verordnungen unterliegen können.
      </p>

      <p>
        Hiermit erkläre ich, dass ich mich mit der Verordnung (EU) 2021/821 (<a href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&amp;from=DE" rel="nofollow noreferrer noopener" target="_blank">https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&amp;from=DE</a>)
         des Europäischen Parlamentes und des Rates vom 20. Mai 2021 über eine Unionsregelung für die Kontrolle der Ausfuhr, der Vermittlung, der technischen Unterstützung der Durchfuhr
         und der Verbringung betreffend Güter mit doppeltem Verwendungszweck, einschließlich deren Anhang (insbesondere Anhang I) auseinander gesetzt habe.
      </p>

      <p>
        Ich versichere, dass ich die aktuellen Embargo-Listen (<a href="https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html" rel="nofollow noreferrer noopener" target="_blank">https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html</a>)
         kontrolliert habe:
      </p>
      <ul>
        <li>
          Ich habe nachgeprüft, dass die Ergebnisse, die in meinen Projekten gewonnen werden und die Hochleistungsrechner-Ressourcen des NHR@FAU nutzen, keiner EU-Verordnung zu Gütern mit
           doppeltem Verwendungszweck unterliegen.
        </li>
      </ul>

      <p>Im Speziellen:</p>
      <ul>
        <li>
          Ich versichere, dass Anhang I der EU-Dual-Use Verordnung Nr. 2021/821 und seine Aktualisierungen keines der in den Projekten erzielten Ergebnisse umfasst.
           (Achtung: Die Anhänge (Güterlisten) der EU-Dual-Use-Verordnung werden jährlich am Anfang des Jahres angepasst!)
        </li>
        <li>
          Ich versichere, dass diese Ergebnisse keinerlei Bezug zu den im Artikel 4 Absatz 1 der EU-Dual-Use-Verordnung Nr. 2021/821 und seinen Aktualisierungen haben.
        </li>
        <li>
          Ich versichere, dass, wenn ich Staatsbürger eines Landes mit Waffenembargo bin, die Ergebnisse mit keinerlei militärischer Nutzung, wie im Artikel 4 Absätze 1 und 3 oder im
           Artikel 4 Absatz 2 der EU-Dual-Use-Verordnung Nr. 2021/821 und seinen Aktualisierungen beschrieben, in Zusammenhang stehen.
        </li>
        <li>
          Ich versichere, dass die Ergebnisse keine Embargo-Verordnung verletzen. Die Systeme von NHR@FAU dürfen insbesondere nicht für Projekte mit russischen Kooperationspartnern
           genutzt werden.
        </li>
        <li>
          Sofern ich PI eines Projekts bin, sorge ich dafür, dass auch alle Leute in meinem HPC-Projekt die Exportkontroll-Verordnungen einhalten.
        </li>
        <li>
          Sofern ich PI oder Technischer Kontakt eines Projekts bin, weise ich neue Projektmitglieder auf die Exportkontroll-Vorschriften hin, bevor ich ihnen eine
           Account-Einladung schicke.
        </li>
      </ul>

      <p>
        <strong>Ich werde dem NHR@FAU jegliche relevante Änderung in Bezug auf das aktuelle Forschungsgebiet melden.</strong>
      </p>
      <p>
        Weitere Informationen zum Thema Exportkontrolle finden Sie auf der FAU Webseite zur Exportkontrolle (<a href="https://www.intern.fau.de/haushalt-und-finanzen/exportkontrolle/" rel="nofollow noreferrer noopener" target="_blank">https://www.intern.fau.de/haushalt-und-finanzen/exportkontrolle/</a>)
         oder dem Bundesamt für Wirtschaft und Ausfuhrkontrolle (BAFA) (<a href="https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html" rel="nofollow noreferrer noopener" target="_blank">https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html</a>).
      </p>
    </div>

    <div v-else>

      <!--<h2>Compliance with European and national export control regulations (as of May 2023)</h2>-->

      <p >
        I am aware that scientific results obtained through the use of high-performance computers may be subject to national, European and international export control regulations.
      </p>

      <p>
        I hereby declare that I have familiarized myself with the regulation (EU) 2021/821 (<a rel="nofollow noreferrer noopener" href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&amp;from=DE" target="_blank">https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021R0821&amp;from=DE</a>)
         of the European Parliament and Council of May 20, 2021 on a Union regulation for the control of exports, brokering, technical support for transit and shipment of dual-use items, including the Annex (in particular Annex I).
      </p>

      <p>
        I assure that I have checked the current embargo lists (<a rel="nofollow noreferrer noopener" href="https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html" target="_blank">https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/Embargos/embargos_node.html</a>):
      </p>
      <ul>
        <li>
          I have verified that the results obtained in my projects using NHR@FAU's high-performance computing resources are not subject to any EU regulation on dual-use items.
        </li>
      </ul>

      <p>Particularly:</p>
      <ul>
        <li>
          I certify that Annex I of the EU Dual-Use Regulation No. 2021/821 and its updates does not include any of the results obtained in the projects.
           (Attention: The appendices (lists of goods) of the EU Dual-Use Regulation are adjusted annually at the beginning of the year!)
        </li>
        <li>
          I certify that these results have no relation to those in Article 4 Paragraph 1 of the EU Dual-Use Regulation No. 2021/821 and its updates.
        </li>
        <li>
          I certify that if I am a citizen of a country with an arms embargo, the results will not be associated with any military use as referred to in Article 4 paragraphs 1
           and 3 or in Article 4 paragraph 2 of the EU Dual-Use Regulation No. 2021/821 and its updates.
        </li>
        <li>
          I assure that the results do not violate any embargo regulation. In particular, the NHR@FAU systems may not be used for projects with Russian cooperation partners.
        </li>
        <li>
          If I am the PI of a project, I make sure that all people in my HPC project comply with the export control regulations.</li>
        <li>
          If I am the PI or Technical Contact of a project, I make new project members aware of the export control regulations before sending them an account invitation.
        </li>
      </ul>

      <p>
        <strong>I will report any relevant change related to the current research area to the NHR@FAU.</strong>
      </p>

      <p>
        Further information on the subject of export controls can be found on the FAU website on export controls (<a rel="nofollow noreferrer noopener" href="https://www.intern.fau.de/haushalt-und-finanzen/exportkontrolle/" target="_blank">https://www.intern.fau.de/haushalt-und-finanzen/exportkontrolle/</a>)
         or the Federal Office of Economics and Export Control (BAFA) (<a rel="nofollow noreferrer noopener" href="http://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html" target="_blank">https://www.bafa.de/DE/Aussenwirtschaft/Ausfuhrkontrolle/ausfuhrkontrolle_node.html</a>).
      </p>

    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Consent',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {},
  data () {
    return {}
  },
  computed: {
    language () {
      return this.$root.$i18n.locale
    }
  },
  watch: {},
  created () {
  },
  methods: {}
}
</script>
