<template>
  <div id="invitation">
    <div class="row justify-content-between">
      <div class="col-12 col-md-5 col-xl-3 mb-1 mb-md-3 ml-md-3">
        <b-button v-if="isStatePending && enableResend" class="w-100" :disabled="resendDisabled" variant="primary" @click.once="resendInvitation()">
          <b-icon-arrow-clockwise shift-v="-1"/> {{ $t('comp.invitation.resend.label') }}
        </b-button>
      </div>
      <div class="col-12 col-md-6 mb-3 mr-3">
        <b-input-group v-if="isStatePending">
          <b-form-input v-model="withdrawalReason" class="w-50" :placeholder="$t('comp.invitation.withdrawplaceholder.label')"/>
          <b-input-group-append>
            <b-button variant="danger" @click="withdrawInvitation()" :disabled="!withdrawalReason">
              <b-icon-x-square shift-v="-1"/> {{ $t('comp.invitation.withdraw.label') }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <p v-if="resendDisabled">
      {{ $t('comp.invitation.resend.success') }}
    </p>

    <InvitationInfo :invitation="invitation" :view="view"/>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import InvitationInfo from '@/components/manager/InvitationInfo'

export default {
  name: 'Invitation',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    InvitationInfo
  },
  props: {
    invitation: {
      type: Object,
      required: true
    },
    enableResend: {
      type: Boolean,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      withdrawalReason: ''
    }
  },
  beforeMount () {
  },
  computed: {
    isStatePending () {
      return this.invitation.state === 'pending'
    },
    resendDisabled () {
      return this.$store.getters['manager/isResendDisabledForInvitation'](this.invitation.invitationId)
    }
  },
  methods: {
    resendInvitation () {
      this.$store.dispatch({ type: 'manager/resendMail', invitationId: this.invitation.invitationId }).then(
        response => {
          this.makeToast(
            this.$i18n.t('resend.text', { id: this.invitation.invitationId, invitee: response.invitee }),
            this.$i18n.t('resend.title'),
            'success'
          )
          this.$store.dispatch({ type: 'manager/disableResendForInvitation', invitationId: this.invitation.invitationId })
        }
      ).catch(
        error => {
          this.makeToast(
            this.$i18n.t('error.text', { id: this.invitation.invitationId, status: error.status, message: 'Internal Server Error: Mail could not be sent!' }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        }
      )
    },
    withdrawInvitation () {
      // State change in store component
      this.$store.dispatch({ type: 'manager/withdrawInvitation', invitation: this.invitation, project: this.invitation.forProject.key, withdrawalReason: this.withdrawalReason }).then(
        response => {
          this.makeToast(
            this.$i18n.t('withdrawn.text', { id: this.invitation.invitationId }),
            this.$i18n.t('withdrawn.title'),
            'success'
          )
        }
      ).catch(
        error => {
          this.makeToast(
            this.$i18n.t('error.text', { id: this.invitation.invitationId, status: error.status, message: 'Internal Server Error: Invitation could not be withdrawn!' }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        }
      )
    },
    // deleteInvitation () {
    //   this.$store.dispatch({ type: 'manager/deleteInvitation', id: this.invitation.invitationId, project: this.invitation.forProject.key }).then(
    //     response => {
    //       this.makeToast(
    //         this.$i18n.t('delete.text', { id: this.invitation.invitationId }),
    //         this.$i18n.t('delete.title'),
    //         'success'
    //       )
    //     }
    //   ).catch(
    //     error => {
    //       this.makeToast(
    //         this.$i18n.t('error.text', { id: this.invitation.invitationId, status: error.status, message: 'Internal Server Error: Invitation could not be deleted!' }),
    //         this.$i18n.t('result.error.title'),
    //         'danger'
    //       )
    //     }
    //   )
    // },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
