<template>
  <div id="invitations">
    <div class="row justify-content-md-start mx-0 mb-3">
      <div class="col-12 mb-3 col-md-4 mb-md-0 pl-0 pr-2">
        <b-form-input size="sm" class="w-100" v-model="invitationFilter" type="search" placeholder="Filter Mail ..."/>
      </div>
      <div v-if="project.isExpired || disableNewInvitation" class="col-6 col-md-auto pl-0 pr-2">
        <b-button variant="success" class="w-100" size="sm" :title="$t('comp.invitations.disabled.title')" disabled>
          <b-icon-plus/> {{ $t('comp.invitations.disabled.label') }}
        </b-button>
      </div>
      <div v-else class="col-6 col-md-auto pl-0 pr-2">
        <b-button variant="success" class="w-100" size="sm" v-b-modal="`invitation-add-${project.projectId}`" @click="add(project)">
          <b-icon-plus/> {{ $t('comp.invitations.add.label') }}
        </b-button>
      </div>
      <div class="col-6 col-md-auto pl-0 pr-2">
        <b-button variant="primary" @click="reloadInvitations()" class="w-100" size="sm">
          <b-icon-arrow-repeat/> {{ $t('comp.invitations.refresh.label') }}
        </b-button>
      </div>
    </div>
    <b-modal :id="`invitation-add-${project.projectId}`" :title="$t('comp.invitations.add.label')" size="xl" @hidden="resetForm">
      <div>
        <b-form @submit="onSubmit" v-if="invitationForm.showForm">
          <InvitationForm :invitation="invitationForm" action="add" view="manager"/>
          <b-button ref="dummyBtn" style="display:none;" type="submit" />
        </b-form>
      </div>
      <template #modal-footer="{ cancel }">
        <b-button variant="dark" @click="cancel()">
          {{ $t('cancel.label') }}
        </b-button>
        <b-button @click="triggerSubmit" variant="primary">
          <b-icon-arrow-up shift-v="-1" class="mr-1"/> {{ $t('submit.label') }}
        </b-button>
      </template>
    </b-modal>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('comp.invitations.pending.label') + ` (${invitationsPending.length})`" active>
        <InvitationsTab :project-id="project.projectId" :project-state="project.state" :invitations="invitationsPending" :filtered="invitationsFiltered"/>
      </b-tab>
      <b-tab :title="$t('comp.invitations.archived.label') + ` (${invitationsArchived.length})`">
        <InvitationsTab :project-id="project.projectId" :project-state="project.state" :invitations="invitationsArchived" :filtered="invitationsFiltered"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import InvitationsTab from '@/components/manager/InvitationsTab'
import InvitationForm from '@/components/generic/form/InvitationForm'
import _ from 'lodash'

export default {
  name: 'Invitations',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    InvitationForm,
    InvitationsTab
  },
  props: {
    project: {
      type: Object
    }
  },
  data () {
    return {
      action: '',
      invitationFilter: '',
      invitationsFiltered: false,
      invitationForm: {
        form: null,
        showDateTimeDecadeNav: true,
        showForm: false,
        invitationSafety: true,
        sendBulk: false,
        showSupervisor: this.project.showSupervisor,
        emptyForm: {
          invitationId: '',
          forMail: null,
          forBulk: [],
          forProject: {},
          supervisor: '',
          forValidity: null,
          message: null,
          state: 'pending'
        }
      }
    }
  },
  computed: {
    invitationsPending () {
      return this.$store.getters['manager/invitationsPendingForProject'](this.project.projectId)
    },
    invitationsArchived () {
      return this.$store.getters['manager/invitationsArchivedForProject'](this.project.projectId)
    },
    disableNewInvitation () {
      if (this.project.state === 'inactive' || this.project.state === 'completed' || this.project.state === 'expired' || this.project.state === 'withdrawn') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    invitationFilter (val) {
      if (val.length >= 3) {
        this.$store.dispatch({ type: 'manager/getInvitationsForProject', projectId: this.project.projectId, filter: val })
        this.invitationsFiltered = true
      } else if (val.length === 0 && this.invitationsFiltered === true) {
        this.$store.dispatch({ type: 'manager/getInvitationsForProject', projectId: this.project.projectId })
        this.invitationsFiltered = false
      }
    }
  },
  created () {
    this.$store.dispatch({ type: 'manager/getInvitationsForProject', projectId: this.project.projectId })
  },
  methods: {
    reloadInvitations () {
      // Setting filter to empty will trigger reload: Force only if filter not set
      this.invitationFilter = ''
      if (this.invitationsFiltered === false) this.$store.dispatch({ type: 'manager/getInvitationsForProject', projectId: this.project.projectId })
    },
    resetForm () {
      this.action = ''
      this.invitationForm.sendBulk = false
      this.invitationForm.form = null
      this.invitationForm.showForm = false
    },
    add (project) {
      this.invitationFilter = ''
      this.action = 'add'
      this.invitationForm.form = _.cloneDeep(this.invitationForm.emptyForm)
      this.invitationForm.form.forProject = { key: project.projectId, label: project.title }
      this.invitationForm.form.forValidity = project?.validUntilAuto ? new Date(Date.parse(project.validUntilAuto)) : new Date(Date.parse(project?.validUntil))
      this.invitationForm.showForm = true
    },
    triggerSubmit () {
      // Without this workaround, the E-Mail field would accept any string!
      // Why? -> Content check works only if enclosing form is submitted, thus: Button triggering hidden submit button.
      this.$refs.dummyBtn.click()
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.action === 'add' && this.invitationForm.sendBulk === false) {
        this.$store.dispatch({
          type: 'manager/addInvitation',
          invitation: this.invitationForm.form,
          invitationSafety: this.invitationForm.invitationSafety
        }).then(
          response => {
            if (response.reactivated) {
              this.makeToast(
                this.$i18n.t('reactivated.text', { account: response.account, code: response.code }),
                this.$i18n.t('comp.invitations.reactivated.title'),
                'success'
              )
            } else {
              this.makeToast(
                this.$i18n.t('created.text', { id: response.invitationId, code: response.code }),
                this.$i18n.t('comp.invitations.created.title'),
                'success'
              )
            }
            // Mail Send Toasts
            if (response?.mailStatus === 500) {
              this.makeToast(
                this.$i18n.t('error.text', { status: response.mailStatus, message: 'Internal Server Error: Invitation created, but mail could not be sent!', id: response.invitationId }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            } else {
              this.makeToast(
                this.$i18n.t('mailsend.text', { id: response.invitationId, formail: this.invitationForm.form.forMail }),
                this.$i18n.t('mailsend.title'),
                'success'
              )
            }
            this.$bvModal.hide(`invitation-add-${this.invitationForm.form.forProject.key}`)
          }
        ).catch(
          error => {
            if (error.status === 409) {
              this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.duplicate.error'), id: error.request.response }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            } else {
              const parsed = JSON.parse(error.request.response)
              this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: parsed.error, id: this.invitationForm.form.forMail }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            }
          }
        )
      } else if (this.action === 'add' && this.invitationForm.sendBulk === true) {
        this.$store.dispatch({
          type: 'manager/addBulkInvitation',
          invitation: this.invitationForm.form,
          invitationSafety: this.invitationForm.invitationSafety
        }).then(
          response => {
            if (!_.isEmpty(response.invitations)) {
              console.log(this.$i18n.t('created.text', { id: response.invitations, code: response.code }))
              this.makeToast(
                this.$i18n.t('created.text', { id: response.invitations, code: response.code }),
                this.$i18n.t('comp.invitations.created.title'),
                'success'
              )
            }
            if (!_.isEmpty(response.reactivations)) {
              console.log(this.$i18n.t('reactivated.text', { account: response.reactivations, code: response.code }))
              this.makeToast(
                this.$i18n.t('reactivated.text', { account: response.reactivations, code: response.code }),
                this.$i18n.t('comp.invitations.reactivated.title'),
                'success'
              )
            }
            this.$bvModal.hide(`invitation-add-${this.invitationForm.form.forProject.key}`)
          }
        ).catch(error => {
          if (error.status === 500) {
            const parsed = JSON.parse(error.request.response)
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: parsed.error, id: parsed.id }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          } else if (error.status === 409) {
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.duplicate.error'), id: error.request.response }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          } else {
            const parsed = JSON.parse(error.request.response)
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: parsed.error, id: this.invitationForm.form.forBulk }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        })
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
</style>
