<template>
  <div class="home" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('app.title') }}</h1>
    <b-card-group deck class="m-3">
      <b-card :header="$t('comp.home.welcome.label')">
        <b-card-text>
          <b-img src="../assets/images/nhr_logo.png" fluid alt="NHR-Logo" class="my-3"></b-img>
        </b-card-text>
        <b-card-text>{{ $t('comp.home.welcome.description') }}</b-card-text>
        <!-- <b-card-text><strong>{{ $t('comp.home.welcome.warning') }}</strong></b-card-text> -->
      </b-card>
    </b-card-group>
    <b-card-group v-if="loggedIn && !hasCorrectAttributes" deck class="m-3">
      <b-card>
        <template #header>
          <b-icon icon="exclamation-circle-fill" scale="1.5" variant="danger" class="mr-2"/>
          <strong>{{ $t('comp.home.attributeerror.label') }}</strong>
        </template>
        <b-card-text>
          {{ $t('comp.home.attributeerror.description') }}
          <br/><br/>
          <ul>
            <li>urn:oid:1.3.6.1.4.1.5923.1.1.1.6 - urn:mace:dir:attribute-def:eduPersonPrincipalName</li>
            <li>urn:oid:0.9.2342.19200300.100.1.3 - urn:mace:dir:attribute-def:mail</li>
            <li>urn:oid:2.5.4.4 - urn:mace:dir:attribute-def:sn</li>
            <li>urn:oid:2.5.4.42 - urn:mace:dir:attribute-def:givenName</li>
            <!-- Optional, but if error anyways, please send this too -->
            <li>urn:oid:1.3.6.1.4.1.5923.1.1.1.9 - urn:mace:dir:attribute-def:eduPersonScopedAffiliation</li>
          </ul>
          <hr/>
          {{ $t('comp.home.currentattributes.description') }}
          <br/><br/>
          <ul>
            <li v-for="current in availableAttributes" :key="current.key">
              <b-icon-check-circle v-if="current.value" class="mr-2" shift-v="-1" variant="success"/>
              <b-icon-x-circle v-else class="mr-2" shift-v="-1" variant="danger"/>
              <b>{{ current.key }}</b>: {{ current?.value ? current.value : $t('comp.home.attributeerror.missingvalue') }}
            </li>
          </ul>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'

export default {
  name: 'Home',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {},
  computed: {
    pageTitle () {
      if (this.messagesAvailable) {
        return this.$i18n.t('app.title')
      }
      return null
    },
    hasCorrectAttributes () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.hasCorrectAttributes
      } else {
        return null
      }
    },
    availableAttributes () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.availableAttributes
      } else {
        return []
      }
    },
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  }
}
</script>
